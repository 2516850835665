<template>
  <v-card class="notificacionesDiarias">
    <v-card-title class="align-start">
      <span class="font-weight-semibold secondary--text">NOVEDADES</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-subtitle class="mb-6 mt-n5">
      <span class="font-weight-semibold text--primary me-1">{{ currentDate }}</span> <br /><br />
      <!-- <span class="primary--text">¡Algo nuevo ha sucedido!</span><br />-->
      <span>No hay novedades por el momento</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="44" color="success" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiWarehouse }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Productos
            </p>
            <v-skeleton-loader max-width="100%" type="text" v-show="loading"></v-skeleton-loader>
            <h3 class="text-xl font-weight-semibold" v-show="!loading">
              {{ data.allProducts }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="44" color="error" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiFormSelect }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Por caducar
            </p>
            <v-skeleton-loader max-width="100%" type="text" v-show="loading"></v-skeleton-loader>
            <h3 class="text-xl font-weight-semibold" v-show="!loading">
              {{ data.numberOfExpiredProducts }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="44" color="primary" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiMapCheck }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Entradas proxímas
            </p>
            <v-skeleton-loader max-width="100%" type="text" v-show="loading"></v-skeleton-loader>
            <h3 class="text-xl font-weight-semibold" v-show="!loading">
              {{ data.upcomingEntries }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiWarehouse, mdiFormSelect, mdiMapCheck } from '@mdi/js'

import moment from 'moment'
import helpers from '../../mixins/helpers'

export default {
  mixins: [helpers],
  data() {
    return {
      icons: {
        mdiWarehouse,
        mdiFormSelect,
        mdiMapCheck,
      },
      data: {
        allProducts: null,
        numberOfExpiredProducts: null,
        upcomingEntries: null,
      },
      currentDate: this.formatDate(moment()),
      loading: false,
    }
  },
  mounted() {
    this.getGeneralInfoAboutProducts()
  },
  methods: {
    getGeneralInfoAboutProducts() {
      this.loading = true
      let url = `${this.$base_api_url}/getGeneralInfoAboutProducts`

      this.$http
        .post(url)
        .then(res => {
          this.data.allProducts = res.data.total
          this.data.numberOfExpiredProducts = res.data.expiring_products
          this.data.upcomingEntries = res.data.upcomingEntries
        })
        .catch(res => {
          this.catchResponse(res)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style>
.notificacionesDiarias {
  min-height: 250px;
}
</style>
