<template>
  <div>
    <v-list class="pb-0 entryListCedis sc2">
      <v-list-item v-for="i in 7" :key="i" :class="`d-flex  align-center pl-0 pr-1`">
        <v-avatar color="primary" size="35" :class="`primary white--text font-weight-medium me-3`">
          <v-icon class="white--text">
            {{ icons.mdiWarehouse }}
          </v-icon>
        </v-avatar>

        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <div class="font-weight-semibold">
              <span class="text--primary text-base me-1">
                <v-skeleton-loader min-width="100px" type="text"></v-skeleton-loader>
              </span>
            </div>

            <v-list-item-subtitle class="text-xs">
              <v-skeleton-loader min-width="100px" type="text"></v-skeleton-loader>
            </v-list-item-subtitle>
          </div>

          <v-spacer></v-spacer>

          <div>
            <h4 class="font-weight-semibold">
              <v-skeleton-loader max-width="" type="text"></v-skeleton-loader>
            </h4>
            <span class="text-xs">Productos</span>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mdiWarehouse } from '@mdi/js'
export default {
  data() {
    return {
      icons: {
        mdiWarehouse,
      },
    }
  },
}
</script>
