<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0 row-xlogistic">
      <v-col cols="12">
        <v-card-subtitle class="text-no-wrap pt-1 ma-0 pa-0 bienvenidaP">
          <span class="bienvenida-title primary--text">¡Bienvenido a {{brand.name}}!</span> <br>
          ¡Es un gusto saludarte de nuevo!
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="row-img">
        <v-img v-if="getUser.img_company != null"
          class="greeting-card-trophy"
          contain
          :src="storagePrivateLinkApi(getUser.img_company)"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import brand from "../../data/brand";
import helpers from '../../mixins/helpers';

export default {
  mixins: [helpers],
  data(){
    return{
      brand:brand
    }
  },
  computed: {
    getUser(){
      return this.$store.getters.getUser
    }
  }
}

</script>

<style lang="scss" scoped>
.greeting-card {
  min-height: 250px;

  position: relative;
  .greeting-card-trophy {
    margin: 0px auto;
    height: 150px !important;
  }
}
.bienvenidaP{
  width: 100%;
  text-align: center;
  padding: 0px !important;
}
.bienvenida-title{
  font-size: 1.5em;
}
.row-img{
  padding: 0px !important;
}
// rtl
/* .v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
} */
</style>
