<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <bienvenida></bienvenida>
      </v-col>
      <v-col cols="12" md="8">
        <novedades></novedades>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <tablaProductosPrioridad></tablaProductosPrioridad>
      </v-col>
      <v-col cols="12" md="4">
        <proximosProveedores></proximosProveedores>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos
import bienvenida from './Bienvenida.vue'
import novedades from './Novedades.vue'
import tablaProductosPrioridad from './tabla-productos-prioridad.vue'
import proximosProveedores from './ProximosProveedores.vue'

export default {
  components: {
    bienvenida,
    novedades,
    tablaProductosPrioridad,
    proximosProveedores,
  },
  data() {
    return {
      totalProfit: {
        statTitle: 'Total Profit',
        icon: mdiPoll,
        color: 'success',
        subtitle: 'Weekly Project',
        statistics: '$25.6k',
        change: '+42%',
      },
      totalSales: {
        statTitle: 'Refunds',
        icon: mdiCurrencyUsd,
        color: 'secondary',
        subtitle: 'Past Month',
        statistics: '$78',
        change: '-15%',
      },
      newProject: {
        statTitle: 'New Project',
        icon: mdiLabelVariantOutline,
        color: 'primary',
        subtitle: 'Yearly Project',
        statistics: '862',
        change: '-18%',
      },
      salesQueries: {
        statTitle: 'Sales Quries',
        icon: mdiHelpCircleOutline,
        color: 'warning',
        subtitle: 'Last week',
        statistics: '15',
        change: '-18%',
      },
    }
  },
}
</script>
