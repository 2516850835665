<template>
  <v-data-table :headers="headers" :items="items" class="elevation-2" hide-default-footer>
    <template v-slot:item="{ headers }">
      <tr class="">
        <td class="text-center" v-for="(h, key) in headers" :key="key">
          <v-skeleton-loader type="table-cell" width="100px" class="mx-auto"></v-skeleton-loader>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    numItems: {
      type: Number,
      default: 10
    },
  },
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.items = this.fillItems(this.numItems)
  },
  methods: {
    fillItems(numItems) {
      let emptyItems = []
      for (let u = 0; u < numItems; u++) {
        emptyItems.push({})
      }
      return emptyItems
    },
  },
  watch: {
    numItems(now) {
      this.items = this.fillItems(now)
    }
  }
}
</script>

<style lang="css"></style>
