<template>
  <v-card>
    <v-card-title>
      <span>¡Atención a estos productos!</span>
    </v-card-title>
    <productos-prioridad :headers="headers" :num-items="tableData.perPage" v-show="tableData.loading"/>
    <v-data-table
      :headers="headers"
      :items="products"
      no-data-text="No hay productos disponibles"
      item-key="full_name"
      class="table-rounded"
      hide-default-footer
      disable-pagination
      disable-sort
      v-show="!tableData.loading"
    >
      <template v-slot:[`item.thumbnail`]="{ item }">
        <v-img v-if="item.thumbnail" :src="`${storagePrivateLinkApi(item.thumbnail)}`" max-height="100px" max-width="100px" contain :aspect-ratio="1"></v-img>
        <v-img v-else :src="getDefaultImage"  max-height="100px" max-width="100px" contain :aspect-ratio="1"></v-img>

      </template>
      <template #[`item.salary`]="{item}">
        {{ `$${item.salary}` }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip small :color="status[item.status].color" class="font-weight-medium">
          {{ status[item.status].text }}
        </v-chip>
      </template>

      <template v-slot:footer>
        <div class="pt-5 px-3 d-flex">
          <div style="width: 100px;">
            <v-select
              v-model="tableData.perPage"
              :items="[5, 10, 20, 30]"
              dense
              hide-details
              class="mt-2"
              outlined
            ></v-select>
          </div>
          <v-spacer></v-spacer>
          <p class="mt-2 mr-3">
            <v-btn icon v-if="tableData.page > 1" @click="tableData.page--">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            {{ tableData.page }}
            <v-btn icon @click="tableData.page++" :disabled="!(products.length > 0)">
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </p>
        </div>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical,mdiChevronLeft,mdiChevronRight } from '@mdi/js'
import status from './../../data/status'
import helpers from '../../mixins/helpers'
import ProductosPrioridad from './skeleton/productos-prioridad.vue';

export default {
  components: {
    ProductosPrioridad
  },
  mixins: [helpers],
  data() {
    return {
      status: status.data,
      headers: [
        { text: 'IMÁGEN', value: 'thumbnail' },
        { text: 'CÓDIGO', value: 'assigned_code' },
        { text: 'NOMBRE', value: 'name' },
        { text: 'DÍAS DE VIDA', value: 'days_of_life' },
        { text: 'ESTATUS', value: 'status' },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiChevronLeft,
        mdiChevronRight
      },
      products: [],
      tableData: {
        page: 1,
        perPage: 5,
        loading: false,
      },
    }
  },
  mounted() {
    this.getExpiringProducts()
  },
  methods: {
    getExpiringProducts() {
      this.tableData.loading = true;
      let url = this.$base_api_url + `/getExpiringProducts`

      let data = {
        perPage: this.tableData.perPage,
        page: this.tableData.page,
      }

      this.$http
        .post(url, data)
        .then(res => {
          this.products = this.$_.map(res.data.data,(item)=>{
            return {
              ...item,
              status: status.getStatusIndex(item.days_of_life)
            }
          });
        })
        .catch(() => {
        }).then(()=>{
          this.tableData.loading = false;
        })
    }
  },
  watch: {
    'tableData.perPage': function(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.tableData.page != 1) {
          this.tableData.page = 1;
        }else{
          this.getExpiringProducts()
        }
      }
    },
    'tableData.page': function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getExpiringProducts()
      }
    },
  },
}
</script>
