<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="cardTitleCedis">Entradas programadas en CEDIS</p>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <proximas-entradas v-show="loading"/>
      <v-list v-show="!loading && appointments.length > 0" class="pb-0 entryListCedis sc2">
        <v-list-item
          v-for="(data, index) in appointments"
          :key="data.appointments_id"
          :class="`d-flex  align-center pl-0 pr-1  ${index > 0 ? 'mt-4' : ''}`"
        >
          <v-avatar color="primary" size="35" :class="`primary white--text font-weight-medium me-3`">
            <v-icon class="white--text">
              {{ icons.mdiWarehouse }}
            </v-icon>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary text-base me-1">{{ formatDatetime(data.date_of_the_appointment) }}</span>
              </div>

              <v-list-item-subtitle class="text-xs">
                {{ formatFolio(data.appointment_id) }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <h4 class="font-weight-semibold">
                {{ data.total_products }}
              </h4>
              <span class="text-xs">Productos</span>
            </div>
          </div>
        </v-list-item>
      </v-list>
      <div v-show="!loading && appointments.length == 0">
        <v-row justify="center">
          <v-col cols="auto">
            <v-icon>{{icons.mdiTruck}}</v-icon>
          </v-col>
          <v-col >
            <p>Por el momento no tienes entradas proxímas.</p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiWarehouse,mdiTruck } from '@mdi/js'
import helpers from '../../mixins/helpers';
import ProximasEntradas from './skeleton/proximas-entradas.vue';
export default {
  mixins: [helpers],
  components: {
    ProximasEntradas
  },
  data() {
    return {
      appointments:[],
      loading: true,
      icons: {
        mdiWarehouse,
        mdiTruck
      },
    }
  },
  mounted() {
    this.getScheduledEntriesInCedis();
  },
  methods: {
    getScheduledEntriesInCedis(){
      this.loading = true;
      let url = `${this.$base_api_url}/scheduledEntriesInCedisApi`;

      this.$http.post(url).then(res=>{
        this.appointments = res.data.appointments;
      }).catch(()=>{
      }).then(()=>{
        this.loading = false;
      });
    }
  }
}
</script>
<style lang="scss" scope>
.cardTitleCedis{
  word-break: break-word;
}
.entryListCedis{
  max-height: 440px;
  overflow-y: scroll;
}
</style>
