export default {
  getStatusIndex(dayOfLife){
    if (dayOfLife > 30) {
      return 3;
    }else if(dayOfLife <= 30 && dayOfLife > 0){
      return 2;
    }else if(dayOfLife == null){
      return null;
    }else{
      return 1;
    }

  },
  data: {
    1: {
      text: 'Caduco',
      color: 'error',
    },
    2: {
      text: 'Por Caducar',
      color: 'warning',
    },
    3: {
      text: 'Vigente',
      color: 'success',
    },
  }
}
